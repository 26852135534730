@import url('https://fonts.googleapis.com/css?family=Nunito:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');

.compatibility-rules {
	::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
	}
	::-webkit-scrollbar {
		width: 5px;
		border-radius: 5px;
		height: 5px;
	}

	::-webkit-scrollbar-thumb {
		width: 5px;
		height: 5px;
		border-radius: 5px;
	}

	.container {
		.main-content {
			padding: 2px;
		}
	}

	h3 {
		font-size: 15px;
		font-weight: 500;
	}


	aside {
		.side-content {
			.content-wrapper {
				.mat-card {
					.mat-divider,
					.mat-divider.mat-divider-horizontal {
						border: 0;
						position: inherit;
						width: 100%;
						margin: 20px 0;
					}

					form {
						display: flex;
						flex-direction: column;
						gap: 5px;
					}

					mat-radio-group.mat-radio-group {
						display: flex;
						gap: 10px;
						flex-direction: column;
					}
				}
			}
		}
	}

	.mat-toolbar-row {
		h3 {
			font-size: 15px;
			font-weight: 500;
		}

		.mat-select {
			width: auto;
			font-size: 14px;

			.mat-select-trigger {
				gap: 10px;
			}
		}
	}

	.charts-group {
		display: flex;
		justify-content: space-between;
		gap: 10px;
		overflow-x: auto;

		chart-wrapper {
			min-width: 200px;
			width: 100%;
			height: auto;
		}
	}

	.mat-card.autoheight {
		height: auto;
	}

	.mat-card {
		.mat-card {
			box-shadow: none;
			padding: 0;
		}
	}

	.mat-divider-horizontal.bottom-spacing + order-list-wrapper,
	.mat-divider-horizontal.both-spacing + order-list-wrapper,
	.mat-divider-horizontal.bottom-spacing + statistic-nested-category,
	.mat-divider-horizontal.both-spacing + statistic-nested-category {
		margin-top: 20px;
		display: block;
	}

	mat-card.note-card {
		background-color: transparent;
		margin-top: -20px;
		padding: 40px 20px 20px 20px;
		border-top: 0;
		margin-bottom: 20px;
		box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
			0px 3px 3px 0px rgb(0 0 0 / 12%);
		height: auto;

		.mat-card-title {
			font-size: 15px;
			font-weight: 400;
			margin-bottom: 20px;
		}

		.mat-card-content {
			font-size: 15px;
			font-weight: 400;
			border-radius: 5px;
			border: solid 1px $border-gray;
			padding: 20px;
			font-size: 14px;
		}
	}

	mat-card {
		.not-visible {
			display: none;
		}
	}

	mat-icon,
	.mat-icon {
		display: inline-flex;
		align-items: center;
		width: 40px;
		height: 40px;
		justify-content: center;
		margin: 0;
		font-size: 16px;
		padding: 0;
		border-radius: 20px;
		flex-shrink: 0;

		svg {
			width: 16px;
			height: 16px;
			font-size: 16px;
			line-height: 16px;
		}
	}

	mat-icon.bordered,
	.mat-icon.bordered {
		border: 1px solid $border-gray;
		color: $text-gray-light;
		fill: $text-gray-light;
	}

	mat-icon.filled,
	.mat-icon.filled {
		background-color: $primary;
		color: #fff;
		fill: #fff;
		border: 0;
	}

	mat-icon.mat-icon.dark-color {
		color: $text-gray;
	}

	.scroll-to-top .mat-fab {
		width: auto;
		height: auto;

		.mat-button-wrapper {
			padding: 0;
		}
	}

	.mat-fab,
	.mat-mini-fab {
		.mat-button-wrapper {
			padding: 0;
		}
	}

	button.mat-fab {
		box-shadow: none;
	}

	button.mat-fab-button,
	button.mat-fab-button__in-page,
	button.mat-fab-button-section-bottom-right {
		.label {
			display: none;
		}

		mat-icon.mat-icon {
			color: #fff;
		}
	}

	button.full-width {
		width: 100% !important;
		justify-content: center !important;
	}

	button.mat-raised-button,
	button.mat-flat-button {
		span.mat-button-wrapper {
			mat-icon.mat-icon {
				color: #fff;
				margin-left: -15px;
			}
		}
	}

	.user-button-container.b2c {
		> button span {
			color: rgba(255, 255, 255, 1) !important;
		}

		> button span.opacity-50 {
			opacity: 1 !important;
		}
	}

	.mt-10 {
		margin-top: 10px !important;
	}

	.mt-50 {
		margin-top: 50px !important;
	}

	.ml-5 {
		margin-left: 5px !important;
	}

	authentication-wrapper {
		.row {
			.mat-form-field-suffix {
				margin-left: 10px;
			}

			input {
				line-height: 23px;
				padding: 0 3px;
			}
		}
	}

	organization-detail-destination {
		> .container-fluid {
			padding: 0;

			.row {
				margin: 0 !important;
				.col-12 {
					padding: 0 !important;
					margin: 0 !important;
				}
			}
		}
	}

	.user-detail {
		form {
			button.upload_image {
				text-align: inherit;
			}
		}
	}

	.show-on-angelo-theme {
		display: none;
	}

	news-detail {
		form.angelo-theme {
			margin-top: 40px;
		}
		form-button-bar {
			display: block;
			margin-top: 40px;
			div.form-button-bar {
				margin-top: 25px;
				padding-top: 25px;
			}
		}
	}

	ngx-slider.ngx-slider {
		.ngx-slider-pointer {
			width: 22px;
			height: 22px;
			top: -8px;
			border-radius: 50%;
		}

		.ngx-slider-pointer.ngx-slider-active::after {
			background-color: $accent;
		}

		.ngx-slider-pointer::after {
			width: 8px;
			height: 8px;
			top: 7px;
			left: 7px;
			border-radius: 50%;
		}

		.ngx-slider-pointer.ngx-slider-pointer-max,
		.ngx-slider-pointer.ngx-slider-pointer-min {
			background-color: #000;
		}

		.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-selection-bar {
			.ngx-slider-span.ngx-slider-bar.ngx-slider-selection {
				background-color: #000;
			}
		}
	}

	td-search-box {
		width: 100%;
	}

	/** regole per la formattazione delle dimensioni del mat-dialog company-info
    /* in authentication-wrapper e della dialogo note-change in article-table-wrapper
    **/

	.cdk-overlay-container {
		.cdk-overlay-pane.cangelo-theme-dialog,
		.cdk-overlay-pane.company-info,
		.cdk-overlay-pane.note-change,
		.cdk-overlay-pane.image-gallery {
			max-width: 60% !important;
			width: 60% !important;
		}
	}

	.catalog-article-table-wrapper {
		td-search-box {
			width: 100%;
		}
		td-data-table:not(.articleColumnMapB2bCatalog) {
			/**
			/* blocco di regole per il settagio della larghezza delle colonne
			/* per ovviare alla impossibilità di settare le alrghezze in article-column-map-b2b-catalog.constant.ts
			/* TO-DO: da contestualizzare tramite classe wrapper identificativa del contesto b2b
			**/
			thead,
			tbody {
				tr {
					th:nth-child(1),
					td:nth-child(1) {
						width: 90px !important;
						max-width: 90px !important;
						min-width: 90px !important;
					}
					th:nth-child(3),
					td:nth-child(3) {
						width: 180px !important;
						max-width: 180px !important;
						min-width: 180px !important;
					}
					th:nth-child(4),
					td:nth-child(4),
					th:nth-child(5),
					td:nth-child(5) {
						width: 100px !important;
						max-width: 100px !important;
						min-width: 100px !important;
					}
					th:nth-child(6),
					td:nth-child(6) {
						width: 120px !important;
						max-width: 120px !important;
						min-width: 120px !important;
					}
					th:nth-child(7),
					td:nth-child(7) {
						width: 50px !important;
						max-width: 50px !important;
						min-width: 50px !important;
					}
					th:nth-child(8),
					td:nth-child(8) {
						width: 120px !important;
						max-width: 120px !important;
						min-width: 120px !important;
					}
				}
			}

			tbody {
				td:nth-child(8) {
					font-weight: 500;
				}
			}
			/** fine blocco settaggio larghezze **/
		}

		td-data-table.articleColumnMapB2bCatalog {
			/**
			/* blocco di regole per il settagio della larghezza delle colonne
			/* per ovviare alla impossibilità di settare le alrghezze in article-column-map-b2b-catalog.constant.ts
			/* TO-DO: da contestualizzare tramite classe wrapper identificativa del contesto b2b
			**/
			thead,
			tbody {
				tr {
					th:nth-child(3),
					td:nth-child(3) {
						width: 90px !important;
						max-width: 90px !important;
						min-width: 90px !important;
					}
					th:nth-child(5),
					td:nth-child(5) {
						width: 180px !important;
						max-width: 180px !important;
						min-width: 180px !important;
					}
					th:nth-child(6),
					td:nth-child(6),
					th:nth-child(7),
					td:nth-child(7) {
						width: 100px !important;
						max-width: 100px !important;
						min-width: 100px !important;
					}
					th:nth-child(8),
					td:nth-child(8) {
						width: 120px !important;
						max-width: 120px !important;
						min-width: 120px !important;
					}
					th:nth-child(9),
					td:nth-child(9) {
						width: 50px !important;
						max-width: 50px !important;
						min-width: 50px !important;
					}
					th:nth-child(10),
					td:nth-child(10) {
						width: 120px !important;
						max-width: 120px !important;
						min-width: 120px !important;
					}
				}
			}

			tbody {
				td:nth-child(10) {
					font-weight: 500;
				}
			}

			article-change-input {
				span {
					margin-top: 10px;
				}
			}
			/** fine blocco settaggio larghezze **/
		}
	}

	.checkout-article-table-wrapper {
		td-search-box {
			width: 100%;
		}
		td-data-table {
			/**
			/* blocco di regole per il settagio della larghezza delle colonne
			/* per ovviare alla impossibilità di settare le alrghezze in article-column-map-b2b-catalog.constant.ts
			/* TO-DO: da contestualizzare tramite classe wrapper identificativa del contesto b2b
			**/
			thead,
			tbody {
				tr {
					th:nth-child(2),
					td:nth-child(2) {
						width: 90px !important;
						max-width: 90px !important;
						min-width: 90px !important;
					}
					th:nth-child(4),
					td:nth-child(4) {
						width: 180px !important;
						max-width: 180px !important;
						min-width: 180px !important;
					}
					th:nth-child(5),
					td:nth-child(5),
					th:nth-child(6),
					td:nth-child(6) {
						width: 100px !important;
						max-width: 100px !important;
						min-width: 100px !important;
					}
					th:nth-child(7),
					td:nth-child(7) {
						width: 120px !important;
						max-width: 120px !important;
						min-width: 120px !important;
					}
				}
			}

			tbody {
				td:nth-child(7) {
					font-weight: 500;
				}
			}

			article-change-input {
				span {
					margin-top: 10px;
				}
			}
			/** fine blocco settaggio larghezze **/
		}
	}

	app-order-detail-checkout {
		> h3 {
			margin-top: 40px !important;
		}
	}

	snack-bar-container {
		.mat-simple-snackbar {
			.mat-simple-snackbar-action {
				button {
					span.mat-button-wrapper {
						color: $accent;
					}
				}
			}
		}
	}

	mc-breadcrumb {
		> span {
			&::before {
				background-color: $icon-gray-light !important;
			}
		}
	}

	media-center {
		mc-media-center {
			media-center-home-sidebar {
				button.bg-alert {
					.mat-button-wrapper {
						mat-icon.mat-icon {
							color: $alert-button-content-color !important;
						}
					}
				}

				button.mat-warn {
					.mat-button-wrapper {
						mat-icon.mat-icon {
							color: $warn-button-content-color !important;
						}
					}
				}

				button.add-folder {
					.mat-button-wrapper {
						mat-icon.mat-icon {
							color: $primary !important;
						}
					}
				}
			}
		}
	}

	page-with-sidebar {
		.container {
			.main-content {
				mat-card.mat-card {
					page-with-sidebar {
						.container.child {
							gap: 10px;
							aside {
								.side-content {
									sidebar-content-wrapper {
										.content-wrapper {
											mat-card.mat-card {
												padding: 0;
												border: 0;
												border-radius: 0;
											}
										}
									}
								}
							}

							&.top {
								aside {
									.side-content {
										sidebar-content-wrapper {
											.content-wrapper {
												mat-card.mat-card {
													padding: 0 0 20px 0;
													border-bottom: solid 1px $border-divider;
												}
											}
										}
									}
								}
							}

							&.bottom {
								aside {
									.side-content {
										sidebar-content-wrapper {
											.content-wrapper {
												mat-card.mat-card {
													padding: 20px 0 0 0;
													border-top: solid 1px $border-divider;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.placeholder {
		margin-top: 20px !important;
	}

	forecast-input-list {
		.forecast-input-list-wrapper {
			.total {
				top: 0px !important;
				max-width: 45px !important;
			}
		}
	}

	// attachment-list-manager {
	// 	attachment-resolver {
	// 		drop-area-file {
	// 			//margin-bottom: 10px;
	// 			display: block;
	// 		}
	// 		.file-selected-container  {
	// 			padding: 0 10px;
	// 			margin-top: 10px !important;
	// 			div {
	// 				display: flex;
	// 				align-items: center;
	// 				margin: 0 !important;
	// 				padding: 0 !important;
	// 			}
	// 			&:nth-of-type(even) {
	// 				background-color: inherit;
	// 			}
	// 		}
	// 	}
	// }

	article-list-structure-backoffice-forecast-wrapper-accordion {
		mat-accordion.mat-accordion {
			mat-expansion-panel.mat-expansion-panel {
				.mat-expansion-panel-content {
					.mat-expansion-panel-body {
						padding-top: 0;
						.forecast-wrapper {
							.list-row {
								padding-top: 10px;
							}

							.chart-wrapper {
								padding-top: 10px;
							}
						}
					}
				}
			}
		}
	}


	attachment-list-manager {

		mat-icon.mat-icon {
			width: 30px;
			height: 30px;
		}

		attachment-resolver {
			drop-area-file {
				//margin-bottom: 10px;
				display: block;
			}
			.file-selected-container  {
				padding: 0 10px;
				margin-top: 10px !important;
				.file-selected {
					display: flex;
					align-items: center;
					margin: 0 !important;
					padding: 0 !important;
				}
				&:nth-of-type(even) {
					background-color: inherit;
				}
			}
		}

		attachment-list-wrapper {
			display: block;

			.title {
				font-size: 15px;
				font-weight: 500;
				font-family: "Roboto";
			}

			.badge {
				display: none;
			}

			td-search-box {
				margin-bottom: 0;
			}
			td-data-table {

				thead {
					display: none;
				}
				table {
					tbody {
						tr {
							td {
								vertical-align: middle;
								div.td-data-table-cell-content-wrapper {
									padding: 0 !important;
									p {
										display: flex;
										align-items: center;
									}
									a {
										color: $text-gray;
										text-decoration: none;
									}
								}

							}
							border: none;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.angelo-theme {
		/** regole per la formattazione delle dimensioni del mat-dialog company-info
        /* in authentication-wrapper
        **/
		.cdk-overlay-container {
			.cdk-overlay-pane.cangelo-theme-dialog,
			.cdk-overlay-pane.company-info,
			.cdk-overlay-pane.note-change,
			.cdk-overlay-pane.image-gallery {
				max-width: 80% !important;
				width: 80% !important;
			}
		}
	}

	.compatibility-rules {
		forecast-input-list {
			.forecast-input-list-wrapper {
				.total {
					top: -30px !important;
					max-width: 100px !important;
				}
			}
		}
	}
}

@media screen and (max-width: 991px) {
	.compatibility-rules {
		.user-button-container.b2c {
			> button span {
				color: rgba(0, 0, 0, 1) !important;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.angelo-theme {
		/** regole per la formattazione delle dimensioni del mat-dialog company-info
        /* in authentication-wrapper
        **/
		.cdk-overlay-container {
			.cdk-overlay-pane.cangelo-theme-dialog,
			.cdk-overlay-pane.company-info,
			.cdk-overlay-pane.note-change,
			.cdk-overlay-pane.image-gallery {
				max-width: 95% !important;
				width: 95% !important;
			}
		}
	}

	.compatibility-rules {
		forecast-input-list {
			.forecast-input-list-wrapper {
				.inputs {
					.column {
						span.prefix {
							display: inline-block !important;
							width: 25px !important;
							text-align: left;
							font-size: 12px;
							color: $text-gray-light;
						}

						input {
							width: calc(100% - 25px) !important;
							text-align: right;
							display: inline-block;
						}
					}
				}

				.total {
					top: -30px !important;
					max-width: 70px !important;
				}
			}
		}
	}
}
