@use '@angular/material' as mat;
@charset 'utf-8';
/*
* styles.scss
* File principale usato dal progetto che importa i parziali
*/

@import '@saep-ict/media-center/media-center-theme';
@import '@saep-ict/angular-core/angular-core-theme';

// il file var è generato grazie allo stylePreprocessorOptions
// a partire dal file _var presente nella cartella del tema usato
@import 'var';

// global-colors contiene le custom properties usate anche da typescript
@import '@saep-ict/angular-core/src/styles/global-colors';

$light-primary: mat.define-palette($mat-primary, 500);
$light-accent: mat.define-palette($mat-accent, 500);
$light-warn: mat.define-palette($mat-warn, 500);
$light-theme: mat.define-light-theme($light-primary, $light-accent, $light-warn);
$theme: mat.define-light-theme($light-primary, $light-accent, $light-warn);

// Generazione dei temi material
@include media-center-theme($theme);
@include angular-core-theme($theme, $font-family-name);

// Import misc
@import './styles/libraries-overrides/libraries-overrides';
@import './styles/shared/shared';
@import './styles/widget/widget';

@import './styles/base-page';
@import './styles/utility';

// Personalizzazione del layout nel contesto pubblico
// indipendentemente dal tema scelto
@import './styles/layout-ctx-public/layout-ctx-public';

// il file theme-override è generato grazie allo stylePreprocessorOptions
// a partire dal file _theme-override presente nella cartella del tema usato
@import 'theme-override';
