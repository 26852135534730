/*
*  Theme name: Customer theme
*  This file contains variables different from var-base.scss
*/

@import '../var-base';

/*
*  override START
*/
$primary: #cc2222;
$accent: #44ccdd;
$secondary: #cc2222;

$alert-button-content-color: $light;
$warn-button-content-color: $light;

/*
*  extend START
*/

/*
*  extend END
*/

@import '../../abstract/abstract';
@import '@saep-ict/angular-core/src/styles/var-base-process';
