// badge
@use '@angular/material' as mat;
$chipSide: 24px;

.chips-mini {
	display: inline-block;
	font-weight: bold;
	margin: 0 auto;
	@include font-size($font-size-sm-map);

	span {
		display: inline-block;
		vertical-align: middle;
		width: $chipSide;
		height: $chipSide;
		line-height: $chipSide;
		margin: 0 2px;
		text-align: center;
		border-radius: 50%;

		+ span {
			width: auto;
			border-radius: 0;
		}
	}
}

// GRIGIO
.DRAFT,
.PROSPECT {
	color: inherit;
	background-color: #e6e6e6;
}

// BIANCO
.BLOCKED,
.SUSPENDED {
	color: #ffffff;
}

// GIALLO
.SEND,
.SUSPENDED,
.LOW,
.QUEUE,
.READY_TO_SEND,
.G {
	background-color: map-get($mat-alert, 500);
	color: mat.get-contrast-color-from-palette($mat-alert, 500);
}

// ARANCIO CHIARO
.RECEIVED,
.SENDING {
	background-color: #ff9d00;
}

// ARANCIO SCURO
.PROCESSING {
	background-color: #cc6600;
}

// VERDE
.WORKING,
.CONSOLIDATED,
.ACTIVE,
.ENABLED,
.AVAILABLE,
.V {
	background-color: $success;
}

// NERO
.DELETED {
	background-color: #000000;
}

// VERDE CHIARO
.PARTIALLY_FULFILLED {
	background-color: #339999;
}

// BLU
.FULFILLED {
	background-color: #0066cc;
}

// ROSSO
.BLOCKED,
.DISABLED,
.CANCELED,
.DELETED,
.NOT_AVAILABLE,
.ERROR_SENDING,
.ERROR_PREPARING,
.R {
	background-color: $warn;
}

// MARRONE
.TO_AUTHORIZE {
	background-color: #996600;
}

// GRIGIO SCURO
.NOT_AUTHORIZED {
	background-color: #808080;
}

.lead {
	background-color: #cc6600;
	color: white;
	font-size: 16px;
}
.prospect {
	background-color: $success;
	color: white;
	font-size: 16px;
}
.customer {
	background-color: #0066cc;
	color: white;
	font-size: 16px;
}
