@import 'var';

@mixin table-heading() {
	.td-data-table-head {
		.td-data-table-heading {
			margin: 8px !important;
			padding: 0 !important;
			white-space: initial;
			line-height: 1;
			text-transform: capitalize;
		}
	}
}

app-root {
	td-data-table {
		border-bottom: none !important;

		.mat-clickable tbody > tr.td-data-table-row:hover {
			cursor: pointer;
		}

		@include table-heading();

		input {
			/* Remove First */
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			display: block;
			width: 100%;
			outline: none;
			text-align: right;

			border-radius: 2px;
			border: 1px solid rgba(0, 0, 0, 0.2);
			padding: 4px;

			&:hover,
			&:active,
			&:focus {
				border: 1px solid map-get($mat-accent, 500);
			}
		}

		.td-data-table-cell {
			vertical-align: top;
			@include font-size($font-size-td-data-table);

			.td-data-table-cell-content-wrapper {
				padding: 8px !important;

				& > * {
					// break the world
					overflow-wrap: break-word;
					max-width: 100%;
				}

				p {
					margin-bottom: 0 !important;
				}
			}
		}
		.td-data-table-scrollable {
			border-top: none;
		}
	}
}

mat-dialog-wrapper {
	td-data-table {
		@include table-heading();
	}
}

th.td-data-table-column {
	@include font-size($font-size-td-data-table);
}

tr.td-data-table-column-row {
	background: #fafafa;
}

mat-icon {
	color: map-get($mat-secondary, 500) default;
}

/* search box */
.td-search-box {
	@include font-size($font-size-default-map);

	.mat-form-field-infix {
		top: 4px;
	}
}
.cell-subtitle {
	opacity: 0.5;
	margin-top: calc($gutter / 4);
}
