/*
*  This file contains only utility class
*/

// icon-label
// utility class to align vertically mat-icon with a text
// example:
// <span class="icon-label">
//     <mat-icon>access_alarm</mat-icon>
//     <span>label</span>
// </span>
.icon-label {
	align-items: center;
	display: inline-flex;
	mat-icon,
	avatar {
		margin-right: 4px;
	}
}

// Colors
.bg-light {
	background: $light;
}
.color-gray {
	color: $gray;
}

// Size
.height-100 {
	height: 100%;
}

// Text
.txt-white {
	color: #ffffff;
}
.txt-primary {
	color: $primary;
}
.txt-accent {
	color: $accent;
}
.txt-green {
	color: $success;
}
.txt-right {
	text-align: right;
}
.txt-center {
	text-align: center;
}
.opacity-50 {
	color: rgba(0, 0, 0, 0.5) !important;
}

.no-data {
	font-style: italic;
	opacity: 0.5;
}

// Position
.center-vertically {
	display: flex;
	align-items: center;
}

// Flex
.flex-span {
	flex: 1 1 auto;
}

.truncate {
	display: -webkit-box;
	max-height: 150px;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.input-highlight input, .input-highlight input + .mask {
	background-color: map-get($mat-accent, 200) !important;
}
